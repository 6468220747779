import type { QueryClient } from '@tanstack/react-query'

import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'

import { CommandDialogWrapper } from '@/components/command-dialog'
import { EnvironmentPanel } from '@/components/environment/panel'

/**
 * Root layout that wraps the entire application.
 */
export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()(
	{
		component: RootLayout,
	},
)

function RootLayout() {
	return (
		<div className="flex h-screen w-full flex-row items-start justify-start overflow-hidden">
			<Outlet />
			<CommandDialogWrapper />
			<EnvironmentPanel />
		</div>
	)
}
