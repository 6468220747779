import { useState } from "react"

export const useSidePanel = () => {
	const [title, setTitle] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [content, setContent] = useState<React.ReactNode>(null)

	const setPanel = (title: string, description: string, content: React.ReactNode) => {
		setTitle(title)
		setDescription(description)
		setContent(content)
	}

	const open = () => setIsOpen(true)
	const close = () => {
		setTitle('')
		setDescription('')
		setContent(null)
		setIsOpen(false)
	}

	const panel = {
		title,
		description,
		content,
		isOpen
	}

	return {
		setPanel,
		open,
		close,
		panel
	}
}
