import type { ClassValue } from 'clsx'

import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * Helper function to merge tailwind classes
 * @param {ClassValue[]} inputs - classes to merge
 * @returns
 */
export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

/**
 * Helper function to delay execution
 * @param {number} ms - milliseconds to wait
 * @returns
 */
export function delay(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}
