import type { ResourcesConfig } from 'aws-amplify'

import { env } from '@/env'

import packageJson from '../../package.json'

// MAGIC NUMBERS
export const ONE_MINUTE = 1000 * 60
export const FIVE_MINUTES = 1000 * 60 * 5
export const ONE_HOUR = 1000 * 60 * 60
export const TWENTY_FOUR_HOURS = 1000 * 60 * 60 * 24 * 24

// ENVIRONMENT
export const isDEV = import.meta.env.DEV
export const isPROD = import.meta.env.PROD
export const isMOCK = env.VITE_USE_MOCK_DATA
export const appVersion = packageJson.version

// API
export const API_URL = `${env.VITE_AIR_API_URL}`

// AMPLIFY/COGNITO
export const amplifyConfig: ResourcesConfig = {
	Auth: {
		Cognito: {
			signUpVerificationMethod: 'code',
			userPoolId: env.VITE_AWS_USER_POOLS_ID,
			userPoolClientId: env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
			loginWith: {
				email: true,
				phone: false,
				username: true,
			},
		},
	},
}

// REACT QUERY
export const QUERY_KEYS = {
	USER: {
		ME: 'me',
	},
	TENANT: {
		GET_USERS: 'tenant-users',
		GET_CONTACTS: 'tenant-contacts',
		GET_BILLING_INFORMATION: 'tenant-billing-information',
		GET_CONTRACT: 'tenant-contract',
		GET_CONTRACT_FILES: 'tenant-contract-files',
	},
	ACTIONS: {
		GET_ACTION_LIST: 'action-list',
		GET_ACTION_ACTIVITIES: 'action-activities',
		GET_ACTION_DETAILS: 'action-details',
		GET_ACTION_ACTIVITY_DETAILS: 'action-activity-details',
	},
	ALERTS: {
		GET_ENABLED_ALERTS: 'enabled-alerts',
	},
}
