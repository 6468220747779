// Remediation Actions Types

export type Action = {
	definition_id: string
	vendor: string
	product: string
	friendly_name: string
	action_id: string
	description: string
}

export type ActionListRequest = {
	actions: Action[]
}

export type ActionInvocation = {
	context: {
		adhoc?: boolean | null
		workflow_instance_id: string
		workflow_action_id: string
		action_uuid: string
		tenant_id: string
		attempt_count?: number | null
		time_scheduled: string
		time_started?: string | null
		time_completed?: string | null
	}
	action_name: string
	action_output?: ActionInputSchema<InputProperty>
	error_info?: string
}

export type ActionActivity = {
	action_uuid?: string
	adhoc?: boolean
	canonical_id?: string
	status: 'pending' | 'running' | 'cancelled' | 'success' | 'error'
	tenant?: string
	time_scheduled?: string
	time_started?: string
	time_completed?: string
	workflow_instance_id?: string
}

export const ACTION_ACTIVITY_STATUS = [
	'pending',
	'running',
	'cancelled',
	'success',
	'error',
] as const

export type ActionDetails = {
	definition_id: string
	vendor: string
	product: string
	input_schema?: ActionInputSchema<InputProperty>
	output_schema?: ActionInputSchema<InputProperty>
	friendly_name: string
	action_id: string
	description: string
}
export type ActionInputSchema<T> = {
	type: string
	properties: Record<string, T>
	title?: string
	required?: string[]
}

/**
 * Type for each field in the schema
 */
export type InputProperty = {
	title?: string
	// the type of the field (number is float, integer is int)
	type: 'string' | 'integer' | 'boolean' | 'object' | 'array' | 'number'
	// if the field is required
	required?: boolean
	// description of the field
	description?: string
	// example here can be used as placeholder
	example?: string
	// default value for the field (e.g. 'true' for boolean, '0' for integer)
	default?: number | string | boolean | null
	// a field can accept multiple inputs, e.g. "email" or "username_uuid"
	anyOf?: InputProperty[]
	// If the field is an array, this is the type of the items in the array
	items?: InputProperty
	// Strings can be formatted as email, phone, uuid, date-time, ipv4, ipv6
	format?:
		| 'email'
		| 'phone'
		| 'uuid'
		| 'date-time'
		| 'ipv4'
		| 'ipv6'
		| 'uri'
		| 'string'
		| 'date'
		| 'time'
	// minimum value of a field
	minimum?: number
	// maximum value of a field
	maximum?: number
	// value but exclusive (e.g. 1-5 but not 5)
	exclusiveMinimum?: number
	exclusiveMaximum?: number
	// value but inclusive (e.g. 1-5 and 5)
	inclusiveMinimum?: number
	inclusiveMaximum?: number
	// number of characters in a string
	minLength?: number
	maxLength?: number
	// multipleOf is a number that the field must be a multiple of
	multipleOf?: number
	// pattern is a regex pattern that the field must match
	pattern?: string
}

export type Module = {
	type?: string
	name: string
	info: Info
	hashes: Hashes
	tenant: string
	lastRun: string
}

export type Info = {
	name: string
	params: Param[]
	description: string
	longdescription: string
	credentials: string[]
}

export type Param = {
	name: string
	example: string
	description?: string
	optional: boolean
}

export type Hashes = {
	md5: string
	sha256: string
	sha3_256: string
}

// Actions Activity Types
export type Invocation = {
	identifier: string
	invoker_id: string
	invoker_name: string
	module: string
	module_name?: string
	status: number
	status_description: string
	tenant: string
	time_completed: string | null
	time_invoked: string
}

// Componenets Types

export type FormProps = {
	module: Action | null
	handleClose: () => void
}
