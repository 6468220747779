import { useSidePanel } from '@/hooks/useSidePanel'

import { ScrollArea } from '../ui/scroll-area'
import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
} from '../ui/sheet'


export const EnvironmentPanel = () => {
	const { panel, close } = useSidePanel()

	return (
		<Sheet open={panel.isOpen} onOpenChange={close}>
			<SheetContent className="w-full sm:min-w-[525px]">
				<SheetHeader>
					<SheetTitle>{panel.title}</SheetTitle>
					<SheetDescription>{panel.description}</SheetDescription>
				</SheetHeader>

				<div className="my-6 flex h-[calc(100vh-9rem)] flex-col items-start justify-start gap-2">
					<ScrollArea className="h-screen w-full">{panel.content}</ScrollArea>

					<div className="flex-auto"></div>
				</div>
			</SheetContent>
		</Sheet>
	)
}
