import { useEffect } from 'react'

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'

import { Amplify } from 'aws-amplify'

import { env } from '@/env'
import { routeTree } from '@/routeTree.gen'

import { amplifyConfig, isDEV } from '@/config/constants'
import { queryClient } from '@/lib/react-query'
import { sentry } from '@/lib/sentry'

import { useAppTheme } from '@/features/app/store'

import { Toaster as Sonner } from '@/components/ui/sonner'

// Setup AWS Amplify
Amplify.configure(amplifyConfig)

/**
 * Persist Query Client
 * This will persist the query client to the browser's local storage, allowing
 * the user to continue from where they left off after a page reload
 *
 * @see https://tanstack.com/query/latest/docs/framework/react/plugins/persistQueryClient
 */
const persister = createSyncStoragePersister({
	storage: window.localStorage,
	key: '@bitlyft/query-cache',
})

// Create a new router instance
const router = createRouter({
	routeTree,
	// Pass the query client to the router context
	context: { queryClient },
	defaultPreload: 'intent',
	// Since we're using React Query, we don't want loader calls to ever be stale
	// This will ensure that the loader is always called when the route is preloaded or visited
	defaultPreloadStaleTime: 0,
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router
	}
}

/**
 * Sentry Initialization
 * This will initialize Sentry with the DSN and Tanstack Router integration
 */
sentry.init({
	dsn: env.VITE_SENTRY_DSN,
	integrations: [sentry.tanstackRouterBrowserTracingIntegration(router)],
	environment: import.meta.env.MODE,
	tracesSampleRate: 0.5,
	sendClientReports: false,
	profilesSampleRate: 1.0,
	enabled: !isDEV,
})

function App() {
	const theme = useAppTheme()

	// Before app mounts, let's set the theme based on the user's system preference
	useEffect(() => {
		// If there's a theme in local storage, use it
		const documentTheme = document.documentElement.classList

		if (theme === 'dark') {
			documentTheme.add('dark')
		} else {
			documentTheme.remove('dark')
		}
	}, [theme])

	return (
		<PersistQueryClientProvider
			client={queryClient}
			persistOptions={{ persister }}
		>
			<RouterProvider router={router} />

			{/* Only load dev tools on development mode */}
			{isDEV && (
				<>
					<ReactQueryDevtools initialIsOpen={false} />
					<TanStackRouterDevtools router={router} position="bottom-right" />
				</>
			)}

			<Sonner />
		</PersistQueryClientProvider>
	)
}

export default App
