import { QueryClient } from '@tanstack/react-query'

import { FIVE_MINUTES } from '../config/constants'

/**
 * React Query Default Options
 * This will set the default stale time for all queries to 5 minutes
 * This can be overridden on a per-query basis
 */
export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: FIVE_MINUTES,
			gcTime: FIVE_MINUTES,
			throwOnError: true,
		},
	},
})
