import { useEffect } from 'react'

import { useNavigate } from '@tanstack/react-router'

import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

import { useAppActions, useAppCommand } from '@/features/app/store'

import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from './ui/command'
import { DialogDescription, DialogTitle } from './ui/dialog'

export const CommandDialogWrapper = () => {
	const navigate = useNavigate()

	const isCommandOpen = useAppCommand()
	const { toggleCommand } = useAppActions()

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				toggleCommand()
			}
		}

		document.addEventListener('keydown', down)

		return () => {
			document.removeEventListener('keydown', down)
		}
	})

	const handleSelect = (option: string) => {
		toggleCommand()
		navigate({
			to: `/${option}`,
		})
	}

	return (
		<CommandDialog open={isCommandOpen} onOpenChange={toggleCommand}>
			<VisuallyHidden.Root id="command-menu">
				<DialogTitle>Command Menu</DialogTitle>
				<DialogDescription>
					Open the command menu to navigate to a page
				</DialogDescription>
			</VisuallyHidden.Root>

			<CommandInput placeholder="Type a command or search..." />
			<CommandList>
				<CommandEmpty>No results found.</CommandEmpty>
				<CommandGroup heading="Overview">
					<CommandItem value="overview/alerts" onSelect={handleSelect}>
						Alerts
					</CommandItem>
					<CommandItem
						value="overview/remediation-actions"
						onSelect={handleSelect}
					>
						Remediation Actions
					</CommandItem>
					<CommandItem
						value="overview/actions-activity"
						onSelect={handleSelect}
					>
						Actions Activity
					</CommandItem>
					<CommandItem value="overview/enabled-alerts" onSelect={handleSelect}>
						Enabled Alerts
					</CommandItem>
				</CommandGroup>
				<CommandSeparator />
				<CommandGroup heading="Settings">
					<CommandItem value="settings/profile" onSelect={handleSelect}>
						Profile
					</CommandItem>
					<CommandItem value="settings/notifications" onSelect={handleSelect}>
						Notifications
					</CommandItem>
					<CommandItem value="settings/team-settings" onSelect={handleSelect}>
						Team Settings
					</CommandItem>
					<CommandItem
						value="settings/contact-directory"
						onSelect={handleSelect}
					>
						Contact Directory
					</CommandItem>
					<CommandItem value="settings/term-dates" onSelect={handleSelect}>
						Term Dates
					</CommandItem>
					<CommandItem value="settings/contract" onSelect={handleSelect}>
						Contract
					</CommandItem>
					<CommandItem
						value="settings/contact-directory"
						onSelect={handleSelect}
					>
						Contact Directory
					</CommandItem>
					<CommandItem value="settings/term-dates" onSelect={handleSelect}>
						Term Dates
					</CommandItem>
				</CommandGroup>
			</CommandList>
		</CommandDialog>
	)
}
